import { findIndex, get } from "lodash";
const severity = {
  safe: "aesthetic",
  unsafe: "critical",
  requirerepair: "functional",
};
export const findSeverity = (name) => {
  if (name in severity) {
    return severity[name];
  }
  return "all";
};

export const keySeverity = {
  Safe: "defect_safe",
  Unsafe: "defect_unsafe",
  "Require Repair": "defect_require_repair",
  Unknown: "defect_unknown",
};

export const findDefectType = (defectOptions, name) => {
  const idx = findIndex(
    defectOptions,
    (defectType) => defectType.value === name
  );
  if (idx > -1) {
    return defectOptions[idx].value;
  }
  return "all";
};

export const defaultConfig = {
  headerBackgroundColor: "#3e3e3e",
  headerTextColor: "#ffffff",
  buttonBackgroundColor: "#60cdf6",
  buttonBackgroundHoverColor: "#22b9ff",
  buttonTextColor: "#3e3e3e",
  buttonTextHoverColor: "#000000",
  columnChartColor: "#7bb3e7",
  secondaryColumnChartColor: "#e7ad7b",
  lineBackgroundColor: "#60cdf6",
  headerLinkColor: "#2e2e2e",
  headerLinkHoverColor: "#60cdf6",
  showBuildingHeader: true,
  logoH3zoom: "",
  logoClient: "/static/h3zoom_logo.png",
  lineHeaderColor: "#ffffff",
  lineHeaderWidth: 5,
  v: 6,
};

export const DATA_STANDARDS = {
  NEN: "NEN",
  PFI: "PFI",
  unknown: "unknown",
};

export const SCORE_DESCRIPTION = {
  1: "Excellent Condition",
  2: "Good Condition",
  3: "Reasonable Condition",
  4: "Poor Condition",
  5: "Bad Condition",
  6: "Very Bad Condition",
  unknown: "Unknown Condition",
};

export const findConditionScoreDescription = (id) => {
  return `${id} - ${get(SCORE_DESCRIPTION, id, SCORE_DESCRIPTION.unknown)}`;
};
export const yellow = "rgb(239, 208, 88)", red = "rgb(226, 87, 87)", orange = "rgb(245, 167, 104)";

export const SEVERITY_COLOR_SET = {
  aesthetic: yellow,
  safe: yellow,
  functional: orange,
  "require repair": orange,
  critical: red,
  unsafe: red,
}

export const INTENSITY_COLOR_SET  = {
  starting: yellow,
  progressing: orange,
  developed: red,
}

export const EXTENT_COLOR_SET  = {
  incidental: yellow,
  localised: yellow,
  regular: orange,
  frequent: orange,
  general: red,
}

export const CONDITION_SCORE_COLOR_SET = {
  1: yellow,
  2: yellow,
  3: yellow,
  4: orange,
  5: orange,
  6: red,
};

/**
 * Find text field color of NEN standard
 * @param field severity, intensity, extent, condition_score
 * @returns yellow | red | orange (rbg color)
 */
export const findTextFieldColor = (field, value) => {
  try {
  if(!value) return 
    switch (field) {
      case 'severity':
        return SEVERITY_COLOR_SET[value.toLowerCase()]
      case 'intensity':
        return INTENSITY_COLOR_SET[value.toLowerCase()];
      case 'condition_score':
        return CONDITION_SCORE_COLOR_SET[value]
      case 'extent':
        const keys = Object.keys(EXTENT_COLOR_SET)
        const item = keys.find(item => value.toLowerCase().includes(item))
        return EXTENT_COLOR_SET[item]
      default:
        return undefined
    }
  } catch (error) {
    console.error(error, field, value)
  }
  return
}

export const KEY_CODES = {
  ENTER_KEY: 13
}