import React, { Component } from "react";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  getLanguageContent,
  getLanguage,
  getConfig,
} from "redux/action/contentPage";
import LangStorage from "utils/LangStorage";
import { isUndefined, isEmpty } from "lodash";
import RootRoute from "routes";
import Navbar from "components/Layout/Header/NavBar/index.js";
import Footer from "components/Layout/Footer";
import LoadingOverlay from "react-loading-overlay";
import ConfigStorage from "utils/ConfigStorage";
import { css } from "emotion";
import { AppContext } from "layout/AppContext";
import { ToastContainer } from "react-toastr";
import AuthStorage from "utils/AuthStorage";
import { defaultConfig } from "utils/Constants";
import ModalChangePassword from "components/Modal/ModalChangePassword";
import ReactGA from "react-ga";
import { ToastContainer as ToastContainerToastify, toast } from 'react-toastify';

function mapStateToProps(state) {
  return {
    store: {
      loadingLanguageContent: state.languageContent.languageContent.loading,
      loadingLanguage: state.languageContent.languageList.loading,
      language: state.languageContent.languageList.data,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getLanguageContent,
        getLanguage,
        getConfig,
      },
      dispatch
    ),
  };
};
let toastr;
class Wrapper extends Component {
  state = {
    config: {},
    isLoading: true,
    firstLogin: false,
    cacheOldPassword: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleGetLanguage();
    const configInCookie = ConfigStorage.getConfig;
    // Check config in cookie
    if (configInCookie && defaultConfig.v === configInCookie.v) {
      this.handleProcessConfig(configInCookie);
    } else {
      this.handleProcessConfig(defaultConfig);
      ConfigStorage.value = { config: defaultConfig };
    }
    const { history } = this.props;
    history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
      window.scrollTo(0, 0);
    });
  }

  handleProcessConfig = (baseConfig) => {
    /* Generate CSS ==> Cache in Context API
      btnRoot ==> Style color of button
      backgroundRoot => Style of background header
      linkRoot ==> Style color + hover of link
      btnRootOutLine ==> Style color outline of button
    */
    const btnRoot = css({
      backgroundColor: baseConfig.buttonBackgroundColor,
      color: baseConfig.buttonTextColor,
      border: "1px solid " + baseConfig.buttonBackgroundColor,
      "&:hover": {
        backgroundColor: baseConfig.buttonBackgroundHoverColor,
        color: baseConfig.buttonTextHoverColor,
      },
    });
    const backgroundRoot = css({
      backgroundColor: baseConfig.headerBackgroundColor,
      color: baseConfig.headerTextColor,
    });
    const linkRoot = css({
      color: baseConfig.headerLinkColor,
      "&:hover": {
        color: baseConfig.headerLinkHoverColor,
      },
      "&.active": {
        color: baseConfig.headerLinkHoverColor,
      }
    });
    const btnRootOutline = css({
      borderColor: baseConfig.buttonBackgroundColor,
      color: baseConfig.buttonBackgroundColor,
    });
    const backgroundLine = css({
      backgroundColor: baseConfig.lineBackgroundColor,
      color: baseConfig.buttonTextHoverColor,
    });
    // const liRoot = css({
    //   backgroundColor: baseConfig.mainTextColor,
    //   color: baseConfig.subTextColor1,
    //   "&:hover":{
    //     backgroundColor:
    //   }
    // })

    this.setState(
      {
        config: {
          btnRoot,
          backgroundRoot,
          linkRoot,
          btnRootOutline,
          baseConfig,
          backgroundLine,
        },
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  handleGetConfig = () => {
    // this.setState({ isLoading: true });
    const payload = {
      organizationId: AuthStorage.organizationId,
    };
    const { getConfig } = this.props.action;
    getConfig(payload, (response) => {
      if (response.status) {
        this.handleProcessConfig(response.data);
        ConfigStorage.value = { config: response.data };
      } else {
        this.handleProcessConfig(defaultConfig);
        ConfigStorage.value = { config: defaultConfig };
      }
    });
  };
  /*
    Get List Language Is Supported
  */
  handleGetLanguage = () => {
    const payload = {};
    const { getLanguage } = this.props.action;
    getLanguage(payload, () => {
      const { language } = this.props.store;
      let currentLanguage = LangStorage.getLang;
      if (isUndefined(currentLanguage) && isEmpty(currentLanguage)) {
        const lang = language.data.find((item) => item.code === "en-us")
          ? "en-us"
          : language.data[0].code;

        LangStorage.value = {
          language: lang,
        };
        currentLanguage = lang;
      }
      this.handleGetLanguageContent(currentLanguage);
    });
  };
  /* Get Content Page by Language */
  handleGetLanguageContent = (languageCode) => {
    const payload = {
      languageCode,
    };
    const { getLanguageContent } = this.props.action;
    getLanguageContent(payload, () => {});
  };
  /* 
  How to use notify ==>
  1. Connect Context
  2. call appContext.notifySuccess("Notify","Example call") */
  notifySuccess = (title, content) => {
    toastr.success(content, title, {
      closeButton: true,
    });
  };
  notifyError = (title, content) => {
    toastr.error(content, title, {
      closeButton: true,
    });
  };
  notifyWarning = (title, content, options) => {
    toast.warn(content, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
      theme: 'light',
      ...options
    });
  };

  handleSetFirstLogin = (isFirstLogin) => {
    this.setState({ firstLogin: isFirstLogin });
  };

  handleSetOldPassword = (password) => {
    this.setState({ cacheOldPassword: password });
  };

  render() {
    const { loadingLanguageContent, loadingLanguage } = this.props.store;
    const { isLoading } = this.state;
    if (loadingLanguage || loadingLanguageContent || isLoading) {
      return (
        <LoadingOverlay active={true} spinner text="Loading content of page...">
          <div style={{ height: "100vh" }} />
        </LoadingOverlay>
      );
    }
    return (
      <AppContext.Provider value={{ appContext: this }}>
        <ModalChangePassword />
        <ToastContainerToastify />
        <ToastContainer
          ref={(ref) => (toastr = ref)}
          className="toast-top-right"
        />
        <Navbar handleGetLanguageContent={this.handleGetLanguageContent} />
        <RootRoute />
        <Footer />
      </AppContext.Provider>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper)
);
