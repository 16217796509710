/**
 * @author Tran Trung
 * @description refactor code and implement redux
 * @copyright 06/06/2019 Kyanon Digital
 */

import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//Authorize layout
import AuthWrap from "layout/Auth";
import SassWrap from "layout/Saas";
import AdminWarp from "layout/Admin";
//pages
import NotFoundPage from "pages/NotFound";
import AutodeskTokenCatcher from "pages/AutodeskTokenCatcher";
import LoginPage from "pages/Login";
import LoadingFullScreen from "components/common/LoadingFullScreen";


const DashboardPage = React.lazy(() => import("pages/Dashboard"));
//Mission page
const UploadAssetsPage = React.lazy(() => import("pages/UploadMission"));
const MissionsListPage = React.lazy(() => import("pages/Mission"));
const MissionDashboardPage = React.lazy(() => import("pages/MissionDetail"));
const NENMissionDashboardPage = React.lazy(() =>
  import("pages/NENMissionDetail")
);
const ProjectDetail = React.lazy(() => import("pages/ProjectDetail"));

//Import profile
const ProfilePage = React.lazy(() => import("pages/UserProfile"));
const BillingPage = React.lazy(() => import("pages/Billing"));
const PricingPage = React.lazy(() => import("pages/Pricing"));
const PaymentPage = React.lazy(() => import("pages/Payment"));

// Authorize page
const RequestResetPasswordPage = React.lazy(() =>
  import("pages/ResetPassword")
);
const ResetPasswordPage = React.lazy(() =>
  import("pages/ResetPassword/ResetPasswordConfirm")
);
const SignUpPage = React.lazy(() => import("pages/Register"));

//Building page
const BuildingsPage = React.lazy(() => import("pages/Building"));
const ProjectsPage = React.lazy(() => import("pages/Project"));
const BuildingDetail = React.lazy(() => import("pages/BuildingDetail"));
const NewBuilding = React.lazy(() => import("pages/NewBuildingPage"));

const UploadElevationDemo = React.lazy(() => import("pages/UploadBuilding"));
const AdminstrationPage = React.lazy(() => import("pages/Adminstration"));



export default function RootRoute() {
  return (
    <Suspense fallback={<LoadingFullScreen />}>
      <Switch>
        <Route exact path="/signin" component={LoginPage} />
        <Route exact path="/registrations" component={SignUpPage} />
        <Route exact path="/pricing" component={PricingPage} />
        <Route
          exact
          path="/password/request-reset"
          component={RequestResetPasswordPage}
        />
        <Route
          exact
          path="/password/reset/key/:uid-:token"
          component={ResetPasswordPage}
        />
        <AuthWrap>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/payments" component={PaymentPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/billing" component={BillingPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <SassWrap>
              <Switch>
                {/* To do ------  */}
                <Route
                  exact
                  path="/administration"
                  component={AdminstrationPage}
                />
                <Route exact path="/buildings" component={BuildingsPage} />
                
                
                <Route exact path="/buildings/:id" component={BuildingDetail} />
                {/* <Route exact path="/inspections/new" component={NewMissionPage} /> */}
                
                <Route exact path="/inspections" component={MissionsListPage} />
                <Route
                  exact
                  path="/buildings/:id/inspections"
                  component={MissionsListPage}
                />
                <Route
                  exact
                  path="/inspections/:id/upload"
                  component={UploadAssetsPage}
                />
                <Route
                  exact
                  path="/inspection/:id"
                  component={MissionDashboardPage}
                />
                <Route
                  exact
                  path="/inspection-nen/:id"
                  component={NENMissionDashboardPage}
                />
                <Route path="/projects" component={ProjectsPage} /> 
                <Route
                  // exact
                  path="/project/:id"
                  component={ProjectDetail}
                />
                <Route
                  exact
                  path="/autodesk"
                  component={AutodeskTokenCatcher}
                />

                <AdminWarp>
                  <Switch>
                    <Route
                      exact
                      path="/buildings-new"
                      component={NewBuilding}
                    />
                    <Route
                      exact
                      path="/buildings/:id/upload"
                      component={UploadElevationDemo}
                    />
                    <Route exact path="*" component={NotFoundPage} />
                  </Switch>
                </AdminWarp>
              </Switch>
            </SassWrap>
          </Switch>
        </AuthWrap>
      </Switch>
    </Suspense>
  );
}
