export * from "./message";

export const GROUP_TYPE_OPTION = {
  Contractor: "Contractor",
  Consultants: "Consultants",
  "RE/RTO": "RE/RTO",
  "OM/PM": "OM/PM",
  "MEP/Archi": "MEP/Archi",
};


export const defaultRoleUser = {
  "is_admin": false,
  "create": {
      "building": false,
      "inspection": false,
      "report": false,
      "project": false,
      "defect rectification": false
  },
  "view": {
      "building": false,
      "inspection": false,
      "report": false,
      "project": false,
      "defect rectification": false
  },
  "approve/reject": {
      "building": false,
      "inspection": false,
      "report": false,
      "project": false,
      "defect rectification": false
  },
  "upload": {
      "building": false,
      "inspection": false,
      "report": false,
      "project": false,
      "defect rectification": false
  },
  "delete": {
      "building": false,
      "inspection": false,
      "report": false,
      "project": false,
      "defect rectification": false
  }
}
export const permissionForClientAdmin = {
  "is_admin": true,
  "create": {
    "building": false,
    "inspection": false,
    "report": true,
    "project": false,
    "defect rectification": false
},
"view": {
    "building": true,
    "inspection": true,
    "report": true,
    "project": false,
    "defect rectification": true
},
"approve/reject": {
    "building": false,
    "inspection": false,
    "report": false,
    "project": false,
    "defect rectification": false
},
"upload": {
    "building": false,
    "inspection": false,
    "report": true,
    "project": false,
    "defect rectification": false
},
"delete": {
    "building": false,
    "inspection": false,
    "report": true,
    "project": false,
    "defect rectification": false
}
}



