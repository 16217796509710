export const initialState = {
  listProject:{
    data: [],
    loading: true
  },
  issuesStatus: {
    data: [],
    loading: true
  },
  calendarContractor: {
    data: [],
    loading: true
  },
  rectificationContractor: {
    data: {},
    loading: true
  },
  overviewContractor: {
    data: {},
    loading: true
  },
  listContractorAndMission: {
    data: {},
    loading: true,
  },
  statisticalContractor: {
    data: {},
    loading: true,
  },
  ratedContractor: {
    data: {},
    loading: true,
  },
  qualityScore: {
    data: [],
    loading: true,
  },
  detailEvaluation: {
    data: {},
    loading: true,
  },
  listOptionBuildingTypeAndCompanyName:{
    data: {},
    loading: true,
  },
  listRangeDefectCount: {
    data:{},
    loading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    //================================================== BEGIN CONTRACTOR MANAGEMENT
    case "GET_LIST_PROJECT_REQUEST":
      return {
        ...state,
        listProject: { ...state.listProject, loading: true },
      };
    case "GET_LIST_PROJECT_SUCCESS":
      return {
        ...state,
        listProject: { data: action.payload.data, loading: false },
      };

    case "GET_ISSUES_STATUS_REQUEST":
      return { ...state, issuesStatus:{...initialState.issuesStatus} }
    case "GET_ISSUES_STATUS_SUCCESS":
      return {
        ...state,
        issuesStatus: { data: [...action.payload.data], loading: false },
      };

    case "GET_CALENDAR_REQUEST":
      return { ...state, calendarContractor:{...initialState.calendarContractor} }
    case "GET_CALENDAR_SUCCESS":
      return {
        ...state,
        calendarContractor: { data: action.payload.data, loading: false },
      };

    case "GET_RECTIFICATION_REQUEST":
      return { ...state, rectificationContractor:{...initialState.rectificationContractor} }
    case "GET_RECTIFICATION_SUCCESS":
      return {
        ...state,
        rectificationContractor: { data: {...action.payload.data}, loading: false },
      };

    case "GET_OVERVIEW_REQUEST":
      return { ...state, overviewContractor:{...initialState.overviewContractor} }
    case "GET_OVERVIEW_SUCCESS":
      return {
        ...state,
        overviewContractor: { data: {...action.payload.data}, loading: false },
      };
  

    //================================================== END CONTRACTOR MANAGEMENT

    //================================================== BEGIN CONTRACTOR EVALUATION
    case "GET_LIST_CONTRACTOR_AND_MISSION_REQUEST":
      return {
        ...state,
        listContractorAndMission: { ...initialState.listContractorAndMission },
      };
    case "GET_LIST_CONTRACTOR_AND_MISSION_SUCCESS": {
      return {
        ...state,
        listContractorAndMission: { data: action.payload, loading: false },
      };
    }
    case "GET_STATISTICAL-CONTRACTOR_REQUEST":
      return {
        ...state,
        statisticalContractor: { ...initialState.statisticalContractor },
      };
    case "GET_STATISTICAL-CONTRACTOR_SUCCESS": {
      return {
        ...state,
        statisticalContractor: { data: action.payload.data, loading: false },
      };
    }
    case "GET_RATED-CONTRACTOR_REQUEST":
      return {
        ...state,
        ratedContractor: { ...initialState.ratedContractor },
      };
    case "GET_RATED-CONTRACTOR_SUCCESS": {
      return {
        ...state,
        ratedContractor: { data: action.payload.data, loading: false },
      };
    }
    case "GET_QUALITY_SCORE_REQUEST":
      return {
        ...state,
        qualityScore: { ...initialState.qualityScore },
      };
    case "GET_QUALITY_SCORE_SUCCESS": {
      return {
        ...state,
        qualityScore: { data: action.payload.data, loading: false },
      };
    }
    case "GET_DETAIL_EVALUATION_REQUEST":
      return {
        ...state,
        detailEvaluation: { ...initialState.detailEvaluation },
      };
    case "GET_DETAIL_EVALUATION_SUCCESS": {
      return {
        ...state,
        detailEvaluation: { data: action.payload.data, loading: false },
      };
    }
    case "OPTION_BUILDING_TYPE_AND_COMPANY_NAME_REQUEST":
      return {
        ...state,
        listOptionBuildingTypeAndCompanyName: { ...initialState.listOptionBuildingTypeAndCompanyName },
      };
    case "OPTION_BUILDING_TYPE_AND_COMPANY_NAME_SUCCESS": {
      return {
        ...state,
        listOptionBuildingTypeAndCompanyName: { data: action.payload, loading: false },
      };
    }
    case "LIST_RANGE_DEFECT_COUNT_REQUEST":
      return {
        ...state,
        listRangeDefectCount: { ...initialState.listRangeDefectCount },
      };
    case "LIST_RANGE_DEFECT_COUNT_SUCCESS": {
      return {
        ...state,
        listRangeDefectCount: { data: action.payload, loading: false },
      };
    }
    //==================================================END CONTRACTOR EVALUATION
    default:
      return state;
  }
};
