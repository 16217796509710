/**
 * run with file .env
 */
import { getCenter } from "geolib";
import AuthStorage from "utils/AuthStorage";
import env from "config/env";
const API_URL_COMMON = env.REACT_APP_URL_API_COMMON;
let urlAPI = env.REACT_APP_URL_API;
export let urlBackend = env.REACT_APP_URL_BACKEND;

let tokenAuthorization = "Token " + AuthStorage.token;
let organization_id = AuthStorage.organizationId;
let emailLogin = AuthStorage.username;
let organization_status = AuthStorage.status;
let user_role = window.localStorage.getItem("user_role");
let isAuth = window.localStorage.getItem("2_Auth");
export const updateToken = (token) => {
  tokenAuthorization = "Token " + token;
};

export const isAuthenticated = () => {
  const accessToken = getAccessToke();
  if (!accessToken) {
    return false;
  }
  return true;
};

export const getAccessToke = () => {
  return AuthStorage.token;
};
export const setToken = (
  token,
  _organization_id,
  _emailLogin,
  _organization_status,
  _user_role,
  _isAuth
) => {
  tokenAuthorization = "Token " + token;
  organization_id = _organization_id;
  emailLogin = _emailLogin;
  organization_status = _organization_status;
  user_role = _user_role;
  isAuth = _isAuth;
  return true;
};

export const getBuilding = (buildingId) => {
  return fetch(urlAPI + "building/" + buildingId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenAuthorization,
    },
  });
};

export const getMapImage = (mission_id) => {
  const url = `${urlAPI}building/get-map-image?mission_id=${mission_id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenAuthorization,
    },
  });
};

export const getMissionImage = (mission_id) => {
  const url = `${urlAPI}mission/${mission_id}/odm-images`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenAuthorization,
    },
  });
};

export const saveBuildingImage = (building_id, image) => {
  const url = `${urlAPI}buildings/update-image`;
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenAuthorization,
    },
    body: JSON.stringify({ building_id, image }),
  });
};

/* unused function */

// export const syncBuilding = (organizationId) => {
//   const url = `${urlAPI}credential/get-credential?app_name=Common_db&organization_id=${
//     AuthStorage.isSuperAdmin ? -1 : organizationId
//   }`;
//   fetch(url, {
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       Authorization: tokenAuthorization,
//     },
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .then((result) => {
//       if (result.status) {
//         const { buildings } = result;
//         if (AuthStorage.isSuperAdmin) {
//           const urlAllBuildingCommon = `${API_URL_COMMON}building/getallbuildings?page=0&pageSize=99999&building_name=`;
//           fetch(urlAllBuildingCommon, {
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${result.token}`,
//             },
//           })
//             .then((responseAllBuildingCommon) => {
//               return responseAllBuildingCommon.json();
//             })
//             .then((resultAllBuildingCommon) => {
//               processBuilding(
//                 buildings,
//                 resultAllBuildingCommon.buildings.rows,
//                 result.token,
//                 -1,
//                 true
//               );
//             });
//         } else {
//           const urlBuildingOrganization = `${API_URL_COMMON}building/getorganizationBuildings?searchOrganization_by=organization_ID&searchOrganizationValue=${organization_id}&app_Name=FI&page=0&&pageSize=99999`;
//           fetch(urlBuildingOrganization, {
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${result.token}`,
//             },
//           })
//             .then((responseBuildingCommon) => {
//               return responseBuildingCommon.json();
//             })
//             .then((resultBuildingCommon) => {
//               processBuilding(
//                 buildings,
//                 resultBuildingCommon.buildingsDetails,
//                 result.token,
//                 organization_id,
//                 false
//               );
//             });
//         }
//       }
//     });
// };

// const processBuilding = (
//   buildingsFI,
//   buildingsCommon,
//   tokenCommon,
//   organizationId,
//   isStaff
// ) => {
//   let diffrentBuilding = [];
//   if (buildingsCommon) {
//     buildingsCommon.map((item, index) => {
//       const findIndex = _.findIndex(
//         buildingsFI,
//         (buildingId) => buildingId.id === item.building_ID
//       );
//       if (findIndex === -1) {
//         diffrentBuilding.push(item);
//       }
//     });
//   }

//   // Check diffrent building to Sync
//   if (diffrentBuilding.length > 0) {
//     diffrentBuilding.map(async (item, index) => {
//       // Internal staff --- Sync building  ---
//       // Get OrganizationId by buildingId
//       // Create new building in FI with OrganizationId
//       if (isStaff) {
//         await fetch(
//           `${API_URL_COMMON}building/searchOrganizationID?building_ID=${item.building_ID}&app_Name=FI`,
//           {
//             method: "GET",
//             headers: {
//               Accept: "application/json",
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${tokenCommon}`,
//             },
//           }
//         )
//           .then((response) => {
//             return response.json();
//           })
//           .then((result) => {
//             if (result.message) {
//               organizationId = -1;
//             } else {
//               organizationId = result.organizationID;
//             }
//           });
//       }
//       if (organizationId !== -1) {
//         let center_point_x = -1;
//         let center_point_y = -1;
//         let listCoordinate = [];
//         try {
//           if (item.buildingElevation.length > 0) {
//             item.buildingElevation.map((face, indexFace) => {
//               const tempStart = face.start_Coordinate.split(",");
//               const tempEnd = face.end_Coordinate.split(",");
//               if (
//                 tempStart &&
//                 tempStart[0] !== "" &&
//                 tempEnd &&
//                 tempEnd[0] !== ""
//               ) {
//                 listCoordinate.push({
//                   latitude: tempStart[0],
//                   longitude: tempStart[1],
//                 });
//                 listCoordinate.push({
//                   latitude: tempEnd[0],
//                   longitude: tempEnd[1],
//                 });
//               }
//             });
//             const center = getCenter(listCoordinate);
//             center_point_x = center.latitude;
//             center_point_y = center.longitude;
//           }
//         } catch {}

//         fetch(`${urlAPI}buildings/new`, {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: tokenAuthorization,
//           },
//           body: JSON.stringify({
//             id: item.building_ID,
//             name: item.building_Name,
//             building_url: item.building_URL, // used to extract building prefix
//             //Hardcode --- Organization id
//             organization_id: organizationId,
//             address: item.building_Street_Name,
//             building_type: item.building_Type,
//             country: item.country || "",
//             postal_code: item.building_Postal_Code || "",
//             model_3d_url: item.model_id || "",
//             number_elevation: item.buildingElevation.length,
//             created: item.createdAt, // used to determined if the data updated
//             updated: item.updatedAt, // used to determined if the data updated
//             center_point_x,
//             center_point_y,
//           }),
//         });
//       }
//     });
//   }
// };

// ---> panh section <---
async function api(url, method = "GET", body) {
  const response = await fetch(
    url,
    method === "POST" || method === "PUT"
      ? {
          method,
          body: JSON.stringify(body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: tokenAuthorization,
          },
        }
      : {
          method,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: tokenAuthorization,
          },
        }
  );

  if (response) {
    return await response.json();
  }
  // window.location.reload();
  return {};
}

export const getListReport = async (payload) => {
  const {
    inspectionId,
    organizationId,
    itemPerPage,
    pageActive,
    keySearch,
  } = payload;
  const uri = `${urlAPI}report/list_reports?organization_id=${organizationId}&inspection_id=${inspectionId}&item_per_page=${itemPerPage}&page=${pageActive}&key_search=${keySearch}`;
  return await api(uri);
};

export const getAcconexReportList = async (payload) => {
  const {
    inspectionId,
    organizationId,
    itemPerPage,
    pageActive,
    keySearchAconex,
  } = payload;
  const uri = `${urlAPI}report/list-aconex-reports?organization_id=${organizationId}&inspection_id=${inspectionId}&item_per_page=${itemPerPage}&page=${pageActive}&search=${keySearchAconex}`;
  return await api(uri);
} 

export const getListReportOptions = async (payload) => {
  const { inspectionId, organizationId } = payload;
  const uri = `${urlAPI}report/list_report_options?organization_id=${organizationId}&inspection_id=${inspectionId}`;
  return await api(uri);
};

export const sendReport = async (payload) => {
  const uri = `${urlAPI}report/share_reports`;
  return await api(uri, "POST", payload);
};

export const downloadAconexReport = async (payload) => {
  const uri = `${urlAPI}report/download-aconex-report`;
  return await fetch(uri, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenAuthorization,
    }
  }).then(response => response.blob())
}

export const uploadReport = async (payload) => {
  const uri = `${urlAPI}report/upload-aconex-report`;
  return await api(uri, "POST", payload)
}

export const createCustomReport = async (payload) => {
  const uri = `${urlAPI}report/create_custom_report`;
  return await api(uri, "POST", payload);
};
// --> Don't touch this <--
