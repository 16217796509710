/**
 * @author Tran Trung
 * @copyright Kyanon Digital
 */

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-spinner-material";
import { withRouter } from "react-router";
import { MAINTENANCE_MESSAGE } from 'constants/index';
import env from 'config/env';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setToken } from "providers/services";
import { loginRequest } from "redux/action/auth";
import { AppContext } from "layout/AppContext";
import AuthStorage from "utils/AuthStorage";
import { Autodesk } from "utils/Autodesk";

import Verify from "./Verify";

function mapStateToProps(state) {
  return {
    store: {
      auth: state.auth,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        loginRequest,
      },
      dispatch
    ),
  };
};

class LoginFrom extends PureComponent {
  state = {
    token: null,
    errors: {},
    loginCheck: false,
    isVerify: false,
    password: "",
    username: "",
    isLogin: false,
    isLoading: false,
    isResend: false,
  };

  componentDidMount() {
    if (AuthStorage && AuthStorage.loggedIn) {
      this.props.history.push("/dashboard");
    } else {
      Autodesk.clearToken()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (AuthStorage && AuthStorage.loggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  hideError = (type) => {
    let errorsCopy = Object.assign({}, this.state.errors);
    errorsCopy[type] = "";
    this.setState({ errors: errorsCopy });
  };

  handleSubmit = (e) => {
    this.setState({
      isLoading: true,
    });
    e.preventDefault();
    let { username, password } = this.refs;
    if (this.handleValidation(username.value, password.value)) {
      this.setState({
        username: username.value,
        password: password.value,
      });
      const value = {
        username: username.value,
        password: password.value,
      };
      this.props.action.loginRequest(
        value,
        async (auth) => {
          if (auth.token) {
            const { appContext } = this.context;
            // console.log("DEBUG ===>", auth);

            if (env.REACT_APP_IS_MAINTENANCE === 'true') {
              const widthNotify = 600;
              appContext.notifyWarning('Notification', MAINTENANCE_MESSAGE, {
                position: 'top-right',
                autoClose: 60000,
                style: {
                  width: widthNotify,
                  right: widthNotify/ 2
                }
              });
            }

            appContext.handleSetOldPassword(password.value);
            appContext.handleSetFirstLogin(auth.firstLogin);

            appContext.handleGetConfig();
            await setToken(
              auth.token,
              auth.organizationId,
              auth.username,
              auth.status,
              auth.role,
              auth.isAuthen
            );
          }
        },
        (err) => {
          this.setState({
            isLoading: false,
          });
          const { auth } = this.props.store;
          const { error } = auth;
          if (!auth.error.mfa_required) {
            let errors = {};
            if (error && error.non_field_errors) {
              errors["non_field"] = error.non_field_errors;
              this.setState({
                errors: errors,
              });
            }
            if (error && error.error) {
              let parseError = [error.error.toString()];
              errors["non_field"] = parseError;
              this.setState({ errors });
            }
          } else {
            this.setState({ isVerify: true });
          }
        }
      );
    }
  };

  handleValidation = (username, password) => {
    const { loginPage } = this.props;
    let formIsValid = true;
    let errors = {};
    //Validate username
    if (!username) {
      formIsValid = false;
      errors["username"] = loginPage.messageError.requiredField;
    } else {
      if (typeof username !== "undefined") {
        let lastAtPos = username.lastIndexOf("@");
        let lastDotPos = username.lastIndexOf(".");
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            username.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            username.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["username"] = loginPage.messageError.invalidUsername;
        }
      }
    }

    //Validate password
    if (!password) {
      formIsValid = false;
      errors["password"] = loginPage.messageError.requiredField;
    }

    this.setState({ errors });
    if (!formIsValid) {
      this.setState({ isLoading: false });
    }
    return formIsValid;
  };

  render() {
    const { loginPage } = this.props;
    const { appContext } = this.context;
    const { config } = appContext.state;

    if (this.state.isVerify) {
      return (
        <Verify
          username={this.state.username}
          password={this.state.password}
          loginPage={loginPage}
          handleGetConfig={appContext.handleGetConfig}
        />
      );
    }
    return (
      <form
        className="form_sign_in"
        onSubmit={this.handleSubmit}
        noValidate={true}
      >
        <div className="signin-notification text-center margin_bottom_10">
          <p>{this.state.errors["non_field"]}</p>
        </div>
        <div className="form-group">
          <input
            type="username"
            name="login"
            placeholder={loginPage.inputEmail}
            autoFocus="autofocus"
            ref="username"
            className={
              "form-control " + (this.state.errors["username"] ? "error" : "")
            }
            id="id_login"
            onChange={() => {
              this.hideError(this, "username");
            }}
          />
          <label id="id_login-error" className="error" htmlFor="id_login">
            {this.state.errors["username"]}
          </label>
        </div>
        <div className="form-group">
          <input
            type="password"
            name="password"
            placeholder={loginPage.inputPassword}
            className={
              "form-control " + (this.state.errors["password"] ? "error" : "")
            }
            ref="password"
            id="id_password"
            onChange={() => {
              this.hideError(this, "password");
            }}
          />
          <label id="id_password-error" className="error" htmlFor="id_password">
            {this.state.errors["password"]}
          </label>
        </div>
        <div className="form-group">
          <div className="checkbox">
            <input
              type="checkbox"
              name="remember"
              className=""
              id="id_remember"
            />
            <label htmlFor="id_remember" className="checkbox-custom">
              {loginPage.textRemember}
            </label>
          </div>
        </div>
        <div className="form-group text-center">
          <button
            className={`btn-root text-uppercase ${config.btnRoot}`}
            type="submit"
            disabled={this.state.isLoading ? "disabled" : ""}
          >
            {this.state.isLoading ? (
              <Spinner
                size={30}
                color={config.baseConfig.buttonTextHoverColor}
                width={2}
                visible={true}
              />
            ) : (
              loginPage.titlePage
            )}
          </button>
          <br />
          <p className="forgot_password">
            <Link
              to="/password/request-reset/"
              className="button secondaryAction"
              href=""
            >
              {loginPage.textForgotPassword}
            </Link>
          </p>
          <p className="forgot_password">
            {loginPage.textSignUp}{" "}
            <Link
              to="/registrations"
              className="button secondaryAction"
              href=""
            >
              {loginPage.hrefSignUp}
            </Link>
          </p>
          <br />
        </div>
      </form>
    );
  }
}
LoginFrom.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginFrom));
